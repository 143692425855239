<template>
    <div>
        <div>
            <el-carousel trigger="click" :height="bannerHeight + 'px'">
                <el-carousel-item v-for="(item,i) in Rotation" :key="i">
                    <img ref="image" :src="item.thumb" alt="" style="width:100%;" @load="imgLoad" @click="jump(item)">
                </el-carousel-item>
            </el-carousel>
        </div>
        <div class="news">
            <div class="title fx">
                <div><img src="shexie/img/new.png" alt="">新闻资讯</div>
                <div style="font-size:16px;color:#333;font-weight:400;" class="cursor" @click="$router.push('/news')">更多资讯 ></div>
            </div>
            <div class="content">
                <img v-if="news" :src="news.thumb" @click="$router.push({path:'/newsDetails',query:{id:news.id}})" alt="" class="left">
                <div class="right">
                    <div v-if="news" class="cursor" @click="$router.push({path:'/newsDetails',query:{id:news.id}})">
                        <div style="font-weight:bold;font-size:24px;">{{news.title}}</div>
                        <div style="color:#CD0000;margin:10px 0px 20px;">{{new Date(news.created_at).toLocaleString()}}</div>
                        <div style="color:#666;font-size:14px;">{{news.desc}}</div>
                    </div>
                    <ul>
                        <li v-for="(item,i) in newsList" :key="i" @click.stop="$router.push({path:'/newsDetails',query:{id:item.id}})"><b></b>{{item.title}}</li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="member_zone">
            <div class="content">
                <div class="title"><img src="shexie/img/member.png" alt="">会员专区</div>
                <div class="fx" style="margin-top:40px;">
                    <div v-for="(item,i) in stepList" :key="i" class="fx">
                        <div class="boxs">
                            <div>{{item.name}}</div>
                            <div class="line"></div>
                            <img :src="item.img" alt="">
                            <div class="desc" v-if="i<5">{{item.desc}}</div>
                            <div class="btn" v-if="i>4" @click="JumpMember">{{item.desc}}</div>
                        </div>
                        <img src="shexie/img/arrow.png" alt="" v-if="i<5" style="margin-left:2px;">
                    </div>
                </div>
            </div>
        </div>
        
        <div class="footer">
            <img src="shexie/img/logo.png" width="400px" alt="">
            <div>地址：北京宋庄</div>
            <div>版权所有 Copyright(C)2021-2025 中国书画文化发展促进会产业专业委员会 <a target="_blank" href="https://beian.miit.gov.cn/#/Integrated/index">ICP备案号： 京ICP备19016869号-2</a></div>
        </div>
        <div class="yingyin" v-show="show">
            <div class="yingyin_box">
                <div>您已申请注册会员<br/>请前往个人中心进行操作</div>
                <div class="fx">
                    <div @click="show=false">取消</div>
                    <div @click="$router.push('/member')">立即前往</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {advs,member_apply} from '@/api/commit'
import {articles_home} from '@/api/news'
export default {
    data() {
        return {
            stepList:[
                {img:'shexie/img/sp1.png',name:'提交会员申请',desc:'用户在线填写申请，请认真填写，已填写的将无法修改'},
                {img:'shexie/img/zf2.png',name:'支付会费',desc:'用户填写会员申请信息后，在线支付会员费'},
                {img:'shexie/img/sh3.png',name:'工作人员审核',desc:'用户支付会员费后工作人员会在规定时间内进行信息审核'},
                {img:'shexie/img/cx4.png',name:'会员状态查询',desc:'信息审核后新用户可进行会员状态查询'},
                {img:'shexie/img/yj5.png',name:'制证邮寄',desc:'会员支付会费后工作人员制作会员证并邮寄给用户'},
                {img:'shexie/img/hy6.png',name:'成为会员',desc:'在线申请'},
            ],
            Rotation:[],
            bannerHeight:'',
            news:{},
            newsList:[],
            show:false
        };
    },
    created() {
        window.addEventListener("resize", () => {
            this.imgLoad();
        });
        this.getAdvs()
        this.getNews()
    },
    mounted() {
    },
    methods: {
        JumpMember(){
            if(localStorage.getItem('sheixetoken')){
                member_apply().then(res=>{
                    if(res.data.flag==true){
                        this.show = true
                    }else{
                        this.$router.push('/memberIndex')
                    }
                })
            }else{
                this.$router.push('/login')
            }
        },
        jump(item){
            // if(item.type=='web_url'){
                window.location.href = item.link
            // }
        },
        //获取首页资讯
        getNews(){
            articles_home().then(res=>{
                if(res.code==200){
                    if(res.data){
                        this.news = res.data[0]
                        res.data.splice(0, 1)
                        this.newsList = res.data
                    }
                }
            })
        },
        imgLoad(){
            this.$nextTick(()=>{
                let ref = this.$refs
                this.bannerHeight=ref.image&&ref.image.length?ref.image[0].height:'';
            })
        },
        //获取首页轮播图
        getAdvs(){
            advs().then(res=>{
                if(res.code==200){
                    this.Rotation = [...res.data]
                }
            })
        }
    }
};
</script>

<style scoped lang="scss">
    .news{
        width: 1200px;
        margin: 60px auto;
        .title{
            img{
                margin-right: 10px;
            }
            color: #CD0000;
            font-size: 24px;
            font-weight: bold;
            margin-bottom:20px;
        }
        .content{
            position: relative;
            .left{
                width: 440px;
                height: 320px;
                position: absolute;
                top:40px;
            }
            .right{
                height:280px;
                width: 680px;
                margin-left: 200px;
                padding: 60px 40px 60px 270px;
                background: #fff;
                ul{
                    margin-top: 40px;
                    li{
                        font-size:18px;
                        color: #333;
                        margin: 10px 0px;
                        overflow: hidden;
                        text-overflow:ellipsis;
                        white-space: nowrap;
                        cursor: pointer;
                        font-weight: bold;
                        b{
                            display: inline-block;
                            margin-right: 10px;
                            width: 10px;
                            height: 10px;
                            background: #CD0000;
                            border-radius: 50%;
                        }
                    }   
                }
            }
        }
    }
    .member_zone{
        margin: 60px 0px;
        background: #fff;
        .content{
            width: 1200px;
            margin: auto;
            padding: 40px 0px;
            .title{
                img{
                    margin-right: 10px;
                }
                color: #CD0000;
                font-size: 24px;
                font-weight: bold;
            }
            .fx{
                .boxs{
                    height: 228px;
                    width: 148px;
                    padding: 15px;
                    text-align: center;
                    border: 1px solid#FFCBCB;
                    border-radius: 4px;
                    .line{
                        width: 40px;
                        height: 2px;
                        background: #CD0000;
                        border-radius: 1px;
                        margin:10px auto;
                    }
                    .desc{
                        font-size: 14px;
                        color: #666;
                    }
                    .btn{
                        width: 120px;
                        height: 40px;
                        line-height: 40px;
                        color: #CD0000;
                        margin: auto;
                        cursor: pointer;
                        text-align: center;
                        border: 1px solid #FF6666;
                        border-radius: 4px;
                    }
                    img{
                        margin: 10px auto 15px;
                    }
                }
            }
        }
    }
    .footer{
        color: #999;
        font-size: 14px;
        text-align: center;
        margin-bottom: 30px;
        div{
            margin: 10px 0px;
        }
        a{
            color: #999;
        }
    }
    .yingyin{
        position: fixed;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background: rgba($color: #000000, $alpha: 0.5);
        z-index: 10;
        .yingyin_box{
            position: absolute;
            left:50%;
            top:50%;
            transform: translate(-50%,-50%);
            width: 344px;
            height: 146px;
            border-radius: 10px;
            background: #fff;
            padding:60px 68px 36px;
            text-align: center;
            .fx{
                margin-top: 60px;
                div{
                    width: 160px;
                    height:40px;
                    line-height: 40px;
                    color: #CC0000;
                    border: 1px solid #CC0000;
                    cursor: pointer;
                    &:last-child{
                        background: #CC0000;
                        color: #fff;
                    }
                }
            }
        }
    }
</style>
